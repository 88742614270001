import React from 'react'
import { graphql } from 'gatsby'
import { HTMLRenderer, Layout, PageWrapper, HcpMobileBanner, CircularInfographics } from '@retina-packages/retina-theme-bootstrap';
import { ContainerWrapper, EmerFooterBlock, TestorRetestPatientSectionWrapper } from '../../../css/common/style'
import { ImportanceOfgeneticTesingWrapper, GTIMobileBanner, HCPGTIBanner } from './styles'
import  MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer';
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes'
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType';
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders';
import { hcpGTI } from './constants';
import { deriveMultiLogo, deriveSiteMenus, footRefHTMLBuilder, fullHTMLDataBuilder } from '../../../utils';
import retinaConfig from '../../../utils/retinaConfigs';

/** Renders genetic-testing-importance-page template
 *
 * @param props props
 * @returns
 */
const GeneticTestingBenefitTemplate = (props: Props) => {
  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso
  const pgContextsGTI = props.pageContext
  const htmlStaticFilesGTI = {
    nonSVGImages: pgContextsGTI.nonSVGImages,
    svgMediaImages: pgContextsGTI.svgMediaImages,
    allMediaDocument: pgContextsGTI.documents
  }

  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: hcpGTI.hcpMainMenu, siteFooterMenu: hcpGTI.hcpHomeFooterMenu, addHCPPrefix: retinaConfig.addHcpPrefix });
  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name
  const bannerTitle = hcpGTI.hcpBanner
  const carouselTitle = hcpGTI.hcpCarousel
  const footerTitle = hcpGTI.hcpFooter
  const exitPopupTitle = hcpGTI.hcpExitPopup
  const hcpSwitcherTitle = hcpGTI.hcpHCPSwitcher
  const topNavTitle = hcpGTI.hcpTopNav
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
  } = reusableDataBuilders({blocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle})


  const hcpReferenceGTB = footRefHTMLBuilder({ blocks, title: hcpGTI.hcpFootRef });
  const siteLogos: any = deriveMultiLogo({ blocks, title: hcpGTI.hcpSiteLogo })

  const hcpInfographicDataGTB: any = blocks.filter((higtb: any) => higtb.relationships.field_reusable_paragraph.label === hcpGTI.hcpInfographic).map(
    (higtbi: any) => higtbi.relationships.field_reusable_paragraph.relationships.paragraphs.relationships.field_paragraph_items_gcso
  ).at(0)

  let per = ""
  const text1 = ""
  let text2 = ""
  per = hcpInfographicDataGTB[0].field_para_text_gcso.value
  if (per) {
    const re = /<p>([0-9]+)<\/p>/
    per = per.replace(re, "$1")
  }
  text2 = hcpInfographicDataGTB[1].field_para_text_gcso.value
  const pieInfoGraData = {'per': per, 'circular_text': text1, 'right_text': text2}
  const htmlData = fullHTMLDataBuilder({ blocks, title: hcpGTI.hcpGTMayPara });
  const retestingData = fullHTMLDataBuilder({ blocks, title: hcpGTI.hcpImpOfRetestPara });
  const calloutSectionData = fullHTMLDataBuilder({ blocks, title: hcpGTI.hcpCalloutSectionPara });
  const revealData = fullHTMLDataBuilder({ blocks, title: hcpGTI.hcpGTCanRevealPara });
  const responseData = fullHTMLDataBuilder({ blocks, title: hcpGTI.hcpResponsePara });
  const knowData = fullHTMLDataBuilder({ blocks, title: hcpGTI.hcpDidYouPara });
  const callOutData = fullHTMLDataBuilder({ blocks, title: hcpGTI.hcpNaviCalloutPara });
  const testOrRetestYourPatientsSectionData = fullHTMLDataBuilder({ blocks, title: hcpGTI.hcpTakeActionPara });
  const bannerHTML = fullHTMLDataBuilder({ blocks, title: hcpGTI.hcpBannerHTML });

  return (
    <HCPGTIBanner className="hcppatientsection">
      {/* Retina Canada HCP - "Importance of Genetic Testing" page - Banner component - EN */}
      <HcpMobileBanner className='imp-gen-testing hcpmobilewrapper'>
        {props.pageContext !== null && props.pageContext.metaInfo !== null && (
          <MetaTagContainer metaData={props.pageContext.metaInfo} />
        )}
        <Layout
          title={"siteTitle"}
          location={props.location}
          modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
          data={mainMenu}
          mdata={footerMenu}
          footerData={footerText}
          audience={audience}
          footref={hcpReferenceGTB}
          hcplinks={hcpSwitcher}
          exitPopData={exitData}
          topNavigation={topNavs}
          siteLogos={siteLogos}
          gtmBackToTopLabel={hcpGTI.backToTopGTM}
          staticMediaFiles={htmlStaticFilesGTI}
          showRefContent={retinaConfig.showRefContent}
          hideRefContent={retinaConfig.hideRefContent}
          hcpValidate={retinaConfig.hcpValidate}
          preIndexUrl = {retinaConfig.preIndexUrl}
          hcpHomeUrl={retinaConfig.hcpHomeUrl}
          hcpPrefix={retinaConfig.hcpPrefix}
          siteHomeUrl={retinaConfig.siteHomeUrl}
          footerClassName={hcpGTI.footerClassName}
          languageConfig={{... retinaConfig.langConfig, ...hcpGTI.redirectLangUrl}}
          {... {...retinaConfig.layoutAttrs.hcpAttrs, ...retinaConfig.layoutAttrs.commonAttrs}}
        >
          <PageWrapper className='pagewrapper importance-genetic'>
            <ImportanceOfgeneticTesingWrapper className="imggentestwrap">
              {/* Banner section */}
              <GTIMobileBanner className="mobile-banner clinical-trials-banner">
                <HTMLRenderer data={htmlStaticFilesGTI} html={bannerHTML} tagName='section' className='internal-banner o-column--full' />
              </GTIMobileBanner>
              {/* Retina Canada HCP - "Importance of Genetic Testing" page - Icon & Text component -EN */}
              <HTMLRenderer html={htmlData} data={htmlStaticFilesGTI} />
              <HTMLRenderer html={retestingData} data={htmlStaticFilesGTI} />
              {/* Retina Canada HCP - "Importance of Genetic Testing" page - Callout component-EN */}
              <HTMLRenderer html={calloutSectionData} data={htmlStaticFilesGTI} tagName={`div`} className={`o-container`} />
              {/* Retina Canada HCP - "Importance of Genetic Testing" page - Donut Chart component -EN */}
              <CircularInfographics data={pieInfoGraData} audience={audience} belowtext={responseData} filedata={htmlStaticFilesGTI} />
              <HTMLRenderer html={knowData} data={htmlStaticFilesGTI} />
              <HTMLRenderer html={revealData} data={htmlStaticFilesGTI} />
              <ContainerWrapper className='container blue-box-wrapper'>
                <TestorRetestPatientSectionWrapper>
                  <HTMLRenderer html={testOrRetestYourPatientsSectionData} data={htmlStaticFilesGTI} tagName={`div`} className={`o-container`} />
                </TestorRetestPatientSectionWrapper>
              </ContainerWrapper>
              <EmerFooterBlock className='emr-footer-block imp-gens'>
                <HTMLRenderer html={callOutData} data={htmlStaticFilesGTI} tagName={`div`} className={`o-container padding-cards-fix`} />
              </EmerFooterBlock>
            </ImportanceOfgeneticTesingWrapper>
          </PageWrapper>
        </Layout>
      </HcpMobileBanner>
    </HCPGTIBanner>
  )
}

export default GeneticTestingBenefitTemplate

export const pageQuery = graphql`
  query ($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ...HcpGTIPageQuery
      }
    }
  }
`
