export const hcpGTI: any = {
  hcpMainMenu: "Main Navigational Menu HCP GCSO",
  hcpFooterMenu: "Footer Navigation Menu HCP GCSO",
  hcpCarousel: "",
  hcpBanner: "Banner content - The Importance Of Genetic Testing",
  hcpBannerHTML: "Banner - HTML - GTI - HCP - Canada",
  hcpFooter: "Retina Global Footer HCP - Canada",
  hcpExitPopup: "Retina Canada HCP Exit Popup HTML",
  hcpHCPSwitcher: "HCP Switcher Modal Pop Up",
  hcpTopNav: "Top Navigational Menu HCP - Canada",
  hcpSiteLogo: "Site logos HCP",
  hcpFootRef: "Reference - The Importance Of Genetic Testing",
  hcpInfographic: "Infographic - Importance Of Genetic Testing",
  hcpGTMayPara: "Genetic Testing May give - Importance of Genetic Testing",
  hcpImpOfRetestPara: "The Importance Of Retesting - Importance of Genetic Testing",
  hcpGTCanRevealPara: "Genetic Testing Can Reveal - The Importance Of Genetic Testing",
  hcpResponsePara: "Response - The importance of genetic testing",
  hcpDidYouPara: "Did You Know - Importance Of Genetic Testing",
  hcpCalloutSectionPara: "Callout Section - Importance Of Genetic Testing HTML",
  hcpTakeActionPara : "Take Action For Confirm - The Importance Of Genetic Testing HTML - Canada",
  hcpNaviCalloutPara: "Navigational CallOut HTML - Canada",
  redirectLangUrl: {
    "redirectUrl": {
      "en": "/hcp/genetic-testing-importance",
      "fr": "/fr/hcp/genetic-testing-importance"
    }
  },
  backToTopGTM: "Back to top - Importance of Genetic testing",
  footerClassName: "genetic-testing-importance-footer"
}
