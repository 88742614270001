import styled from 'styled-components'
import { device } from '@retina-packages/retina-theme-bootstrap/packages/css/device'
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles';

export const ImportanceOfgeneticTesingWrapper = styled.div`
	.internal-banner__image {
		img {
			object-fit: cover;
			width: 100%;
			object-position: center center;
			height: 100%;
			display: block;

			@media ${device.laptopPros} {
				height: 100%;
    		object-position: 93% 0%;
			}

			@media ${device.ipadLandscape} {
				height: 100%;
    		object-position: 90% 0%;
			}
		}
	}

	.internal-banner__contant {
		.internal-banner__large-title,
		h1 {
			h1 {
				font-size: 5.3rem;
				line-height: 5.9rem;
				color: #fff;
				text-transform: uppercase;
				padding: 0 0 0px;
				max-width: 70%;
				margin: 0;
				font-weight: 700;

				@media ${device.laptopMedium} {
					text-align: center;
					width: 100%;
					margin: auto;
					max-width: 100%;
					font-size: 3.2rem;
					line-height: 3.8rem;
				}
			}
		}
	}

	.fullwidth-listing {
		padding: 80px 0 120px;
    @media ${device.extraLargeDesktop}{
      padding: 150px 0 120px;
    }

		@media ${device.laptopMedium} {
			padding: 27px 0 48px;
		}

		.o-container {
			.o-inner {
				padding: 0 100px;

				@media ${device.laptopMedium} {
					padding: 0;
				}

				.o-header {
					color: #000000;
					font-weight: 700;
					text-align: center;
					font-size: 4.2rem;
					line-height: 5rem;

					@media ${device.laptopMedium} {
						font-size: 2.5rem;
						line-height: 3rem;
					}

					strong {
						font-weight: 700;
					}

					span {
						color: #8000BE;
					}
				}

				.full-listing-block {
					display: inline-block;
					padding: 0;
					list-style: none;
					margin: 40px 0 0;

					@media ${device.laptopMedium} {
						margin: 20px 0 0;
					}

					li {
						color: #000000;
						padding: 12px 0;
						line-height: 3.2rem;
            font-family: "Gotham", Arial, Helvetica, sans-serif;
						font-weight: normal;
						font-size: 2.7rem;
						text-indent: -42px;

						@media ${device.laptopMedium} {
							line-height: 2.4rem;
							position: relative;
							padding: 8px 0 8px 40px;
							color: #000000;
							font-size: 2rem;
							text-indent: unset;
						}

						span {
							display: inline-block;
							background-color: #8000BE;
							color: #ffffff;
							border-radius: 50%;
							text-align: center;
							width: 38px;
							height: 38px;
							line-height: 38px;
							font-size: 2.2rem;
							margin: 0 4px 0 0;
							text-indent: 0;

							@media ${device.laptopMedium} {
								width: 32px;
								height: 32px;
								line-height: 32px;
								font-size: 2rem;
								position: absolute;
								left: 0;
								margin-right: 0;
								margin-top: -3px;
								text-indent: unset;
							}
						}

						a {
							color: #000000;
							cursor: pointer;
							text-decoration: none;
						}
					}
				}
			}
		}
	}

	.generic-testing-benefits {
		padding: 0px 140px;
		position: relative;

		@media ${device.laptopMedium} {
			padding: 0;
		}

		&:before {
			content: "";
			background-color: #00FFD9;
			position: absolute;
			top: 120px;
			left: 0;
			height: calc(100% - 305px);
			width: 100%;
		}

		.importance-rest--block {
			padding: 0 0 50px;
			background-color: #F7F7F7;
			box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
    	position: relative;

			@media ${device.laptopPros} {
				margin: 0 28px;
				padding: 0 0 30px;
			}

			&:before {
				content: "";
				background: linear-gradient(90deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
				position: absolute;
				top: 0;
				width: 100%;
				height: 22px;
				left: 0;
				right: 0;

				@media ${device.laptopMedium} {
					height: 12px;
				}
			}

			.faq-block {
				padding-top: 62px;
				position: relative;

				@media ${device.laptopMedium} {
					padding-top: 42px;
				}

				h2 {
					color: #000000;
					text-align: center;
					padding-bottom: 40px;
					font-size: 4.2rem;
					font-weight: 600;
					line-height: 5rem;

					@media ${device.laptopPros} {
						width: 80%;
						margin: auto;
						display: block;
					}

					@media ${device.laptopMedium} {
						padding-bottom: 20px;
						font-size: 2.5rem;
    				line-height: 3rem;
					}
				}

				.faq-question {
					max-width: 85%;
    			margin: 0 auto 50px;
					position: relative;

					@media ${device.laptopMedium} {
						margin: 0 auto 20px;
						text-align: center;
					}

					img {
						position: absolute;
						top: 5px;
						left: 0;

						@media ${device.laptopMedium} {
							width: 50px;
							position: unset;
							left: unset;
							top: unset;
							margin-bottom: 15px;
							height: auto;
						}
					}

					.faq-answer {
						padding-left: 110px;

						@media ${device.laptopMedium} {
							padding-left: unset;
							text-align: center;
						}

						.o-paragraph {
							font-weight: 500;
							line-height: 3.2rem;
							color: #000000;
							font-size: 2.2rem;

							@media ${device.laptopMedium} {
								font-size: 1.8rem;
								line-height: 2.6rem;
							}

							strong {
								font-weight: 700;
							}

							sup {
								font-size: 60%;
								top: -0.5em;
								line-height: 0;
								vertical-align: baseline;
								position: relative;
							}
						}
					}

					&:nth-child(3) {
						img {
							transform: rotate(15deg);
							width: 68px;
							height: 97px;
						}
					}

					&:last-child {
						img {
							width: 70px;
							height: 85px;

							@media ${device.laptopMedium} {
								width: 40px;
								height: auto;
							}
						}
					}
				}
			}
		}
	}

	.case-studies--block {
		padding: 88px 0 0;

		@media ${device.laptopMedium} {
			padding: 10px 0 0;
		}

		.o-columns--row {
			justify-content: center;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			align-items: stretch;
    	margin: 30px 0 0;

			@media ${device.laptopMedium} {
				display: block;
			}

			.text-bg-box-wrapper {
				max-width: 450px;
				margin: 0 20px;
				padding: 30px 25px 100px;
				box-sizing: border-box;
				position: relative;
				text-align: center;
				width: 48.5%;
				background-color: #8000BE;
				display: block;

				@media ${device.laptopMedium} {
					max-width: 340px;
					margin: 20px auto;
					width: 100%;
					padding: 15px 20px !important;
				}

				.o-columns--two {
					.media--image {
						img {
							display: block;
							margin: 0 auto;

							@media ${device.laptopMedium} {
								margin: 10px auto;
								width: 50px;
							}
						}
					}

					img {
						display: none;
					}

					.o-paragraph {
						margin: 30px auto;
						width: 100%;
						padding: 0;
						text-align: center;
						color: #fff;
						font-size: 2.2rem;
						font-weight: 500;
						line-height: 3.2rem;

						@media ${device.laptopMedium} {
							line-height: 22px;
							width: 100%;
							font-size: 1.8rem;
							margin: 16px 0 32px;
						}
					}

					.o-button {
						position: absolute;
						bottom: 40px;
						left: 0;
						right: 0;
						margin: 0 auto;
						display: inline-block;
						max-width: 252px;
						padding: 10px;
						color: #8000BE;
						background-color: #fff;
						font-size: 2.2rem;
						font-weight: 500;
						line-height: 3.2rem;
						text-decoration: none;
						z-index: 1;
						vertical-align: top;
						overflow: hidden;
						padding: 10px 25px;
						border: none;
						letter-spacing: 0;
						border-radius: 6px;
						background: #fff;
						transition: all 0.3s ease;
						cursor: pointer;

						@media ${device.laptopMedium} {
							margin: 0 auto 10px;
							position: unset;
							font-size: 1.8rem;
							display: block;
							max-width: 245px;
							width: 100%;
						}

						&:hover {
							color: #fff;
							background-color: #7323CA;
						}

						&:after {
							content: '';
							display: block;
							width: 16px;
							height: 14px;
							float: right;
							position: relative;
							top: 10px;
							left: 7px;

							@media ${device.laptopMedium} {
								left: 0;
							}
						}
					}
				}
			}
		}
	}

	.genetic-cause-wrapper {
		.o-inner {
			padding: 0;

			.genetic-cause {
				width: 80%;
				padding: 120px 50px;
				margin: 0 auto;

				@media ${device.laptopMedium} {
					width: 100%;
					margin: 20px 0 30px;
					padding: 24px 0;
					flex-direction: column;
				}

				.genetic-cause__right-text-secion {
					padding-left: 60px;

					@media ${device.laptopMedium} {
						padding-left: 0;
					}
				}
			}
		}
	}

	.image-points-section {
		padding-bottom: 88px;

		@media ${device.ipadLandscapemin} and ${device.ipadairpro} {
			padding-left: 5rem;
			padding-right: 5rem;
		}

		@media ${device.laptopMedium} {
			padding-bottom: 40px;
		}

		h2 {
			width: 60%;
			display: block;
			margin: auto;
			font-size: 4.2rem;
			font-weight: 700;
			line-height: 5rem;
			color: #000000;
			text-align: center;

			@media ${device.laptopMedium} {
				padding: 0 30px;
				width: 100%;
				display: block;
    		margin: auto;
				text-align: center;
				font-size: 2.5rem;
				line-height: 3rem;
			}
		}

		.listing-btm-section {
			padding: 50px 0 0;
			display: flex;
			flex-wrap: wrap;
			align-items: baseline;

			@media ${device.laptopMedium} {
				display: block;
				padding: 20px 0 0;
			}
			@media ${device.laptop} and ${device.minimumDeskEnd} {
				padding-left: 5rem;
				padding-right: 5rem;
			}

			.media--image {
				width: 47.4%;
				margin: 0 auto;

				@media ${device.desktopStart} {
					width: 48%;
				}

				@media ${device.laptopL} {
					width: 46%;
				}

				@media ${device.extraLargeDesktop} {
					width: 46.5%;
					max-width: unset;
				}

				@media ${device.laptopMedium} {
					width: 100%;
					margin: 0 auto 17px;
				}

				.gatsby-image-wrapper {
					>div {

						@media ${device.desktopStart} {
							max-width: 805px !important;
						}
					}

					img {
						@media ${device.desktopStart} {
							max-width: 805px;
							width: 805px;
						}

						@media ${device.extraLargeDesktop} {
							max-width: 884px;
							width: 884px;
						}
					}
				}
			}

			.right-listing-points {
				margin: initial;
				align-items: flex-end;
				justify-content: end;
				padding-left: 48px;
				width: 51.6%;
				padding-right: calc((100vw - 1168px) / 2);
				display: table;

				@media ${device.laptopMedium} {
					margin-top: 30px;
					padding-left: 40px;
					padding-right: 40px !important;
					width: 100%;
					margin: 30px 0 0 0;
				}

				.o-paragraph {
					font-size: 2.2rem;
					font-weight: 500;
					line-height: 3.2rem;
					color: #000000;

					@media ${device.laptopMedium} {
						margin-top: 0px;
						font-size: 1.8rem;
						line-height: 2.6rem;
					}
				}

				.listing-section {
					margin-top: 28px;
					margin: auto;
    			display: table;
					padding: 0;

					@media ${device.laptopMedium} {
						margin-top: 15px;
					}

					li {
						padding: 0 0 24px 25px;
            font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
						display: inline-block;
    				position: relative;
						font-size: 2.2rem;
						font-weight: 500;
						line-height: 3.2rem;
						color: #000000;

						@media ${device.laptopMedium} {
							padding: 0 0 10px 25px;
							margin-top: 0px;
							font-size: 1.8rem;
    					line-height: 2.6rem;
						}

						&:before {
							content: "";
							position: absolute;
							background-color: #8000BE;
							width: 8px;
							height: 8px;
							top: 12px;
							margin: auto;
							left: 0;
						}

						sup {
							font-size: 60%;
							line-height: 0;
							vertical-align: baseline;
							position: relative;
							top: -0.5em;
						}
					}
				}
			}
		}
	}

	.blue-box-wrapper {
		margin-bottom: 120px;
		padding: 0;

		@media ${device.tablet} and ${device.ipadLandscape}  {
			padding: 0 5rem;
		}

		@media ${device.laptopMedium} {
			margin-bottom: 50px;
		}

		.o-genes-wrap {
			margin: 0 40px;

			@media ${device.laptop} and ${device.midDesktopMax} {
				margin: 0;
			}

			@media ${device.laptopMedium} {
				margin: 0 22px;
			}

			.info-box-2 {
				padding: 40px 30px;

				@media ${device.laptopMedium} {
					padding: 20px 46px 30px;
					box-shadow: 0 4px 6px #cecece;
				}
			}
		}
	}

	.generic-testing-benefits-cover-bg.o-section--cover-bg {
		.o-container {
			margin: 0 auto;
			max-width: 1208px !important;
			padding: 0 2rem;

			h2.o-header {
				font-size: 4.2rem;
				font-weight: 700;
				line-height: 5rem;
				color: rgb(0, 0, 0);
				text-align: center;
				padding: 0px 0px 40px;
			}

			.o-columns {
				max-width: 780px;
				margin: 0px auto 140px;
				width: 80%;
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;
				color: rgb(0, 0, 0);
				text-align: center;
			}
		}
	}

	.emr-footer-block {
		.footer-block {
			&.footer-block-bg {
				background: transparent;

				h3 {
					&.o-common-title, h3 {
						font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
					}
				}
				span {
					&.text-para-wrap {
						@media ${device.laptopPros} {
							line-height: 2.6rem !important;
						}

						p {
              font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
						}
					}
				}
			}
      .footer-block--row{
        .footer-inner-block{
          p{
            a{
              &:after{
                width: 16px;
                height: 14px;
              }
            }
            span {
              &.text-para-wrap {
                font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
              }
            }
          }
        }
      }
		}
	}
`;

export const HcptestResetpatientSection = styled.div`

.c-hide-references {
  .extlink {
    &:focus {
      text-decoration: underline;
    }
    &:focus-within {
      text-decoration: underline;
    }
    &:focus-visible {
      text-decoration: underline;
    }
  }
  ul{
    li{
      a{
        color: rgb(0, 255, 217);
        text-decoration: underline;
        text-underline-offset: 2px;

      }
    }
  }
}

.headerwraphead {
  .containerWidth {
    .topnav {
      button {
        @media ${device.mobileMedium} {
          top: 310px;
        }
      }
    }
  }
}

.header-inner {
  .navbar-nav {
    .navitemstyle {
      @media ${device.desktopsignup} and  ${device.midDesktopMax} {
        padding: 0 10px;
      }
    }
  }

  .navbar {
    .navbar-collapse {
      .navbar-nav {
        @media ${device.mobileMedium} {
          min-height: 450px;
        }
      }
    }
  }
}

.topnav {
	button {
		@media ${device.mobileStart} and ${device.minideskend} {
			width: 80%;
			top: 400px;
		}
	}
}

  .imggentestwrap {
    .testing-benefits-image-points-section {
      .o-internal--h2 {
        sup {
          font-size: 60%;
          position: relative;
          line-height: 0;
          vertical-align: baseline;
        }
      }
    }

    .fullwidth-listing {
      .o-container {
        .o-inner {
          .full-listing-block {
            padding-left: 50px;

            @media ${device.laptopMedium} {
              padding-left: 0;
            }
          }

          .o-header {
            sup {
              font-size: 60%;
              position: relative;
              line-height: 0;
              vertical-align: baseline;
            }
          }
        }
      }

      li {
        u {
          text-decoration: none;
        }
      }
    }
  }

  .generic-testing-benefits-cover-bg {
    .o-container {
      div.o-columns {
        &.o-columns--gtb {
          margin: 0px auto 40px;

          @media ${device.ipadLandscapemin} {
            margin: 0px auto 60px;
          }
        }
      }
    }
  }

  .imp-gen-testing {
    &:not(.patient-genetic){
      .show{
      +.main {
          padding-top: 0px;
        }
      }
    }
    .o-container {
      .genetic-cause__left-img-secion {
        .percentblock {
          margin: auto;
        }
      }

      .genetic-cause {
        padding-bottom: 0;
      }

      .below-text {
        max-width: 767px;
        margin: 0 auto;
        padding-left: 100px;
        position: relative;
        bottom: 40px;
        font-size: 1.6rem;
        font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
        font-weight: 500;

        @media ${device.laptopMedium} {
          text-align: center;
          bottom: 0;
          padding-left: 0;
        }

        @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
          padding-left: 200px;
          bottom: 20px;
        }

        @media ${device.smalldeskstrt} and ${device.midDesktopMax} {
          padding-left: 200px;
          bottom: 20px;
        }
      }
    }


    .importance-genetic {
      .internal-banner {
        @media ${device.mobileMedium} {
          height: 332px;
        }
        @media ${device.largeDesktop} {
          height: 372px;
        }
        .internal-banner__image {
          @media ${device.mobileMedium} {
            height: 310px;
          }
        }

      }
    }
  }

  .blue-box-wrapper {
    .o-container {
      .o-inner-wrapper-gens {
        .o-genes-wrap {
          @media ${device.desktopStart} {
            background: #300064;
            width: 90%;
            margin: 0 auto;
          }

          .info-box-2 {
            @media ${device.desktopStart} {
              margin: 0 auto;
            }

            p {
              @media ${device.desktopStart} {
                width: 84%;
              }
            }
          }
        }
      }
    }
  }
`;

export const GTIMobileBanner = styled(MobileBanner)`
.internal-banner {
  .internal-banner__image {
    @media ${device.mobileMedium} {
      height: 310px;
    }
  }
}`;

export const HCPGTIBanner = styled(HcptestResetpatientSection)`
.imp-gen-testing {
  .importance-genetic {
    .internal-banner {
      .internal-banner__image {
        @media ${device.mobileMedium} {
          height: 330px;
        }
      }
    }
  }
}

.clinical-trials-banner{
  height: 100%;
  .internal-banner{
    .gatsby-image-wrapper-constrained{
      width:100%;
      @media ${device.laptopMedium}{
        height: 100%;
        object-position: 90% 0%;
      }
      @media ${device.desktopsignup}{
        margin-top: -2px;
      }
      @media ${device.largeDesktop}{
        height: 380px;
      }
      @media ${device.extraLargeDesktop}{
        height: 410px;
      }
    }
    &:after{
      @media ${device.largeDesktop}{
       top: 94%;
      }
      @media ${device.extraLargeDesktop}{
        top: calc(100% + 36px);
      }
    }
    .internal-banner__contant{
      .o-container{
        padding:0 3rem;

        @media ${device.tablet}{
          padding-top:0;
        }
        @media ${device.ipadLandscapemin}{
          padding: 0 5rem;
        }
        @media ${device.desktopStart}{
          padding: 0 2rem;
        }

        h1{
          @media ${device.ipadLandscapemin} and ${device.desktopmenu} {
            margin: 15px 0;
            width: 100%;
          }
          @media ${device.extraLargeDesktop}{
            margin-top: 5rem;
          }
        }
      }
    }
    .internal-banner__image{
      @media ${device.laptopMedium}{
        height: 470px;
      }
      .desktop-image{
        height: 100%;
          .media--image{
            height: 100%;
          }
        img{
          display: block;
          object-fit: cover;
          width: 100%;
          height: 100%;
          object-position: 90% 0%;

          @media ${device.laptopPros}{
            display: none;
          }
          @media ${device.ipadLandscapemin} {
            width: 100%;
          }
        }
      }
      .mobile-image{
        .media--image{
          height: 100%;
        }
        img{
          display: none;
          @media ${device.laptopPros}{
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100%;
            object-position: center !important;
          }
        }
      }
    }
  }
}

// .footer{
//   @media ${device.tablet} and ${device.laptopMedium} {
//     margin: -24rem auto 0;
//   }
//   .o-backtotop-btn{
//     @media ${device.laptopMedium}{
//       background-color: transparent;
//     }
//   }
//   .footercontainer{
//     @media ${device.mobileMedium}{
//       margin: 80px auto 0;
//     }
//     @media ${device.ipadLandscapemin} and ${device.minideskend} {
//       margin: 150px auto 0px;
//     }
//     @media ${device.laptop} {
//       margin: 40px auto 0px;
//     }
//   }
// }
`;
